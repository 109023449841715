:root {
    --ag-green: green;
    --ag-dark-green: rgb(1, 69, 1);
    --ag-white: white;

    
  }



main {
    min-height: 85vh;
}

h4 {
    margin: 0.8rem 0;
}

a {
    text-decoration: none;
}

.storeLink {
    text-decoration: none;
    background-color: var(--ag-green);
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    color: var(--ag-white);
}

h1 {
    padding: 1rem 0;
    font-size: 1.8rem;
}

h2 {
    padding: 0.2rem 0;
    font-size: 1.4rem;
}

table td {
    vertical-align: middle;
}

.alert-custom {
    transition: all 0.4s ease-in-out;
}

.rating span {
    margin: 0.1rem;
}

button.cart-delete-btn {
    margin-left: 1em;
}

.form-control:focus {
    box-shadow: none;
}

@media (max-width: 430px) {
    button.remember-password {
        font-size: 0.8rem;
    }
}

/* EDITED CLASSES */

.color-nav {
    background-color: var(--ag-white);
    height: 56px
}

.logo {
    object-fit: contain;
    width: 100%;
}

.header-option {
    color: green;
}

.bg-carousel {
    background-color: var(--ag-green);
    border-radius: 16px;
}


.go-back {
    border: 1px solid var(--ag-green);
    border-radius: 8px;
    background-color: var(--ag-white);
    color: var(--ag-green);
    padding: 0.5em;
    text-decoration: none;
    margin-bottom: 4px;
}

.go-back:hover {
    border: 1px solid var(--ag-green);
    border-radius: 8px;
    background-color: var(--ag-green);
    color: var(--ag-white);
    padding: 0.5em;
    text-decoration: none;
    margin-bottom: 4px;
}

.ag-btn-primary {
    border: 1px solid var(--ag-green);
    border-radius: 8px;
    background-color: var(--ag-green);
    color: var(--ag-white);
    padding: 0.5em;
    text-decoration: none;
    margin-bottom: 4px;
}
.ag-btn-primary:hover {
    border: 1px solid var(--ag-green);
    border-radius: 8px;
    background-color: var(--ag-dark-green);
    color: var(--ag-white);
    padding: 0.5em;
    text-decoration: none;
    margin-bottom: 4px;
}

.message {
    background-color: green;
}
/* UTILS

import getSymbolFromCurrency from 'currency-symbol-map'
{`Price: ${getSymbolFromCurrency('NGN')}
*/
/* =========================== */

main {
    min-height: 85vh;
}

h4 {
    margin: 0.8rem 0;
}

h1 {
    padding: 1rem 0;
    font-size: 1.8rem;
}

h2 {
    padding: 0.2rem 0;
    font-size: 1.4rem;
}

table td {
    vertical-align: middle;
}

.alert-custom {
    transition: all 0.4s ease-in-out;
}

.rating span {
    margin: 0.1rem;
}

button.cart-delete-btn {
    margin-left: 1em;
}

.form-control:focus {
    box-shadow: none;
}

@media (max-width: 430px) {
    button.remember-password {
        font-size: 0.8rem;
    }
}